define("bo/components/table-cells/fids", ["exports", "bo/components/table-cells/default"], function (_exports, _default2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _default2.default.extend({
    fids: Ember.computed('item', function () {
      const field = this.get('field');
      const value = this.get(`item.${field}`);

      if (parseInt(value) <= 0) {
        return null;
      }

      return value;
    })
  });

  _exports.default = _default;
});