define("bo/templates/components/table-cells/array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rjuiSRqC",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"list\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bo/templates/components/table-cells/array.hbs"
    }
  });

  _exports.default = _default;
});