define("bo/controllers/bases/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      delete(record) {
        if (confirm("Êtes-vous sûr de vouloir supprimer cet élément ?")) {
          record.destroyRecord();
        }
      }

    }
  });

  _exports.default = _default;
});