define("bo/components/table-cells/notification-actions", ["exports", "bo/components/table-cells/default"], function (_exports, _default2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _default2.default.extend({});

  _exports.default = _default;
});