define("bo/adapters/application", ["exports", "active-model-adapter", "bo/config/environment"], function (_exports, _activeModelAdapter, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelAdapter.extend({
    namespace: "api/api/admin",
    host: _environment.default.APP.API_URL,
    headers: Ember.computed("session.authToken", () => Object.assign(currentUser.getHeaders(_environment.default.APP.ENDPOINT), {
      "Content-type": "application/json"
    })).volatile()
  });

  _exports.default = _default;
});