define("bo/transforms/base64", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize()
    /*serialized*/
    {
      return null;
    },

    serialize(deserialized) {
      if (deserialized == null) {
        return deserialized;
      }

      return deserialized.split(',')[1];
    }

  });

  _exports.default = _default;
});