define("bo/components/forms/array-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    newValue: "",
    errorValue: "",

    init() {
      this._super(...arguments);

      this.set("list", []);
    },

    isValidValue() {
      function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }

      if (!this.newValue) return false;
      return this.get("type") === "email" ? validateEmail(this.newValue) : true;
    },

    actions: {
      addField() {
        if (this.isValidValue()) {
          this.get("list").pushObject(this.newValue);
          this.set("newValue", "");
          this.set("errorValue", "");
          this.onUpdate(this.list);
        } else {
          this.set("errorValue", "La valeur n'est pas valide");
        }
      },

      removeField(index) {
        this.get("list").removeAt(index);
        this.onUpdate(this.list);
      }

    }
  });

  _exports.default = _default;
});