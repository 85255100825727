define("bo/templates/components/table-cells/editable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eZIRO381",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"class\",\"hover\"],[9],[0,\"\\n  \"],[1,[21,\"value\"],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"onclick\",[23,[\"item\"]],[23,[\"field\"]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bo/templates/components/table-cells/editable.hbs"
    }
  });

  _exports.default = _default;
});