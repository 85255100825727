define("bo/helpers/dynamic-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dynamicParams = dynamicParams;
  _exports.default = void 0;

  function dynamicParams([routeName, id, params]
  /*, hash*/
  ) {
    return [routeName, id, {
      isQueryParams: true,
      values: params
    }];
  }

  var _default = Ember.Helper.helper(dynamicParams);

  _exports.default = _default;
});