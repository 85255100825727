define("bo/templates/components/top-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gMRrHIif",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"topbar\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"topbar-left\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"index\"],[[\"class\"],[\"logo\"]],{\"statements\":[[0,\"      \"],[7,\"img\"],[12,\"src\",[28,[[21,\"rootURL\"],\"/assets/images/logoFidmeA.png\"]]],[11,\"alt\",\"fidme\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bo/templates/components/top-bar.hbs"
    }
  });

  _exports.default = _default;
});