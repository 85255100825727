define("bo/components/table-cells/address", ["exports", "bo/components/table-cells/default"], function (_exports, _default2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _default2.default.extend({
    fullAddress: Ember.computed('item.address.{address1,address2,zipcode,city}', function () {
      const address = this.get('item.address');
      return [address.get('address1') || '', address.get('address2') || '', address.get('zipcode') || '', address.get('city') || ''].join(' ');
    })
  });

  _exports.default = _default;
});