define("bo/components/list-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Whether or not to show the "previous" button
    showPrevious: Ember.computed('page', function () {
      return this.get('page') > 1;
    }),
    // Whether or not to show the "previous" button
    showNext: Ember.computed('items', 'perPage', function () {
      return this.get('items').length >= this.get('perPage');
    }),
    nextPage: Ember.computed('page', function () {
      return this.get('page') + 1;
    }),
    previousPage: Ember.computed('page', function () {
      return this.get('page') - 1;
    }),
    // The text to display on the "next" button
    nextText: '>',
    // The text to display on the "previous" button
    previousText: '<',
    // The text to display on the "previous" button
    beginText: '<<'
  });

  _exports.default = _default;
});