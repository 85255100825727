define("bo/instance-initializers/auth-proxy", ["exports", "snapp-auth-proxy", "bo/config/environment"], function (_exports, _snappAuthProxy, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _snappAuthProxy.Config.url = _environment.default.APP.API_URL;
    window.currentUser = new _snappAuthProxy.Authentication();
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});