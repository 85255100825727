define("bo/templates/components/table-cells/file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sOD2e0hW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\"],[12,\"href\",[28,[[21,\"file\"]]]],[11,\"target\",\"maps\"],[9],[0,\"\\n  Télécharger\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bo/templates/components/table-cells/file.hbs"
    }
  });

  _exports.default = _default;
});