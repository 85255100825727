define("bo/transforms/content", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize(serialized) {
      const content_label = {
        'creditable': "Programme Créditable",
        'scannable': "Programme Scannable",
        'stampable': "Programme Stampable"
      };
      serialized.label = content_label[serialized.type];
      serialized.codeParams = serialized.codeParams || {
        bcid: "",
        length: ""
      };
      return serialized;
    },

    serialize(deserialized) {
      return deserialized;
    }

  });

  _exports.default = _default;
});