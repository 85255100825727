define("bo/components/input-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    searchValue: Ember.computed('value', function () {
      return this.get('value');
    }),
    actions: {
      search(value) {
        this.set('searchValue', value);
        this.set('value', value);
      }

    }
  });

  _exports.default = _default;
});