define("bo/templates/components/forms/rows/email-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AWeYXGhr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"form-group row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-12 col-lg-6\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"label\"],[11,\"class\",\"col-2 col-form-label\"],[9],[0,\"E-mail\"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-10\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"input-group\"],[9],[0,\"\\n          \"],[14,1],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bo/templates/components/forms/rows/email-row.hbs"
    }
  });

  _exports.default = _default;
});