define("bo/router", ["exports", "bo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route("login");
    this.route("bundles", function () {
      this.route("index");
      this.route("new");
      this.route("edit", {
        path: "/:id/edit"
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});