define("bo/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async model() {
      return {
        isAuthenticated: await currentUser.isAuthenticated()
      };
    },

    async beforeModel() {
      if (await currentUser.isAuthenticated()) return;
      this.replaceWith("login");
    },

    actions: {
      willTransition() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});