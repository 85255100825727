define("bo/controllers/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    email: null,
    password: null,
    actions: {
      async login() {
        if (await currentUser.authenticate(this.email, this.password)) {
          this.transitionToRoute("/");
        }
      }

    }
  });

  _exports.default = _default;
});