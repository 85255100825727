define("bo/templates/components/forms/cells/offer-catalog-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wkfl78jr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"m-b-20\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col\"],[9],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"class\",\"placeholder\"],[\"text\",[23,[\"catalog\",\"name\"]],\"form-control\",\"Nom du catalogue\"]]],false],[0,\"\\n\\n      \"],[7,\"input\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"uploadCSV\",[23,[\"div\"]],[23,[\"index\"]],[23,[\"catalog\"]]],null]],[11,\"accept\",\"text/csv\"],[11,\"class\",\"form-control\"],[11,\"type\",\"file\"],[9],[10],[0,\"\\n      \"],[1,[27,\"textarea\",null,[[\"value\",\"class\",\"disabled\"],[[23,[\"codesList\"]],\"form-control\",true]]],false],[0,\"\\n      \"],[1,[21,\"csvLinesCount\"],false],[0,\" lignes chargées\\n      \"],[7,\"button\"],[11,\"class\",\"btn btn-primary btn-small\"],[9],[0,\"Supprimer\"],[3,\"action\",[[22,0,[]],\"deleteCatalog\",[23,[\"catalogs\"]],[23,[\"index\"]],[23,[\"catalog\"]]]],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn btn-primary btn-small\"],[9],[0,\"Enregistrer\"],[3,\"action\",[[22,0,[]],\"saveCatalog\",[23,[\"catalog\"]]]],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"hr\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bo/templates/components/forms/cells/offer-catalog-cell.hbs"
    }
  });

  _exports.default = _default;
});