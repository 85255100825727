define("bo/templates/bundles/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v2u1oV+r",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ol\"],[11,\"class\",\"breadcrumb\"],[9],[0,\"\\n  \"],[7,\"li\"],[11,\"class\",\"breadcrumb-item active\"],[9],[0,\"Bundles\"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"card-box table-responsive\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[0,\"Bundles\"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col text-right\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"bundles.new\"],[[\"class\"],[\"btn btn-secondary right\"]],{\"statements\":[[0,\"        Nouveau bundle\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"br\"],[9],[10],[0,\"\\n  \"],[1,[27,\"table-default\",null,[[\"data\",\"fieldsList\",\"fieldsLabels\",\"fieldsWidth\",\"showButton\",\"deleteButton\",\"delete\",\"emptyLabel\"],[[23,[\"model\",\"bundles\"]],[23,[\"model\",\"fieldsList\"]],[23,[\"model\",\"fieldsLabels\"]],[23,[\"model\",\"fieldsWidth\"]],false,true,[27,\"action\",[[22,0,[]],\"delete\"],null],[23,[\"emptyLabel\"]]]]],false],[0,\"\\n\\n\"],[10],[0,\"\\n\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bo/templates/bundles/index.hbs"
    }
  });

  _exports.default = _default;
});