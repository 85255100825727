define("bo/controllers/bases/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    modelPath() {
      return this.get("model");
    },

    willDestroy() {
      if (this.modelPath().get("isNew")) {
        this.modelPath().deleteRecord();
      } else {
        this.modelPath().rollbackAttributes();
      }
    },

    actions: {
      async save() {
        await this.modelPath().save();
        this.transitionToRoute("bundles.index");
      }

    }
  });

  _exports.default = _default;
});