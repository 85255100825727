define("bo/components/forms/select-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedOption: null,
    showLineAll: true,
    sortingBy: Object.freeze(['label']),
    sortedList: Ember.computed.sort('list', 'sortingBy'),
    actions: {
      setSelection: function (selected) {
        this.set('selectedOption', selected);
        this.get('selectionHandler')(selected);
      }
    }
  });

  _exports.default = _default;
});