define("bo/components/bundles/edit-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      upload(model) {
        const input = document.querySelector("input[type=file]");

        if (input.files && input.files[0]) {
          if (!input.files[0].type.includes("zip")) return alert("Vous ne pouvez importer que des fichiers zip");
          const reader = new FileReader();

          reader.onload = e => {
            const fileToUpload = e.srcElement.result;
            model.set("file", fileToUpload);
          };

          reader.readAsDataURL(input.files[0]);
        }
      }

    }
  });

  _exports.default = _default;
});