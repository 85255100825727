define("bo/templates/components/list-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wucMo4m4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[23,[\"items\"]]]],[0,\"\\n\\n\"],[4,\"if\",[[27,\"gt\",[[23,[\"items\",\"length\"]],0],null]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,[\"showPrevious\"]]],null,{\"statements\":[[4,\"link-to\",[[23,[\"resource\"]],[27,\"query-params\",null,[[\"page\"],[1]]]],null,{\"statements\":[[0,\"      \"],[1,[21,\"beginText\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",[[23,[\"resource\"]],[27,\"query-params\",null,[[\"page\"],[[23,[\"previousPage\"]]]]]],null,{\"statements\":[[0,\"      \"],[1,[21,\"previousText\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n  Page \"],[1,[21,\"page\"],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"showNext\"]]],null,{\"statements\":[[4,\"link-to\",[[23,[\"resource\"]],[27,\"query-params\",null,[[\"page\"],[[23,[\"nextPage\"]]]]]],null,{\"statements\":[[0,\"      \"],[1,[21,\"nextText\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bo/templates/components/list-pagination.hbs"
    }
  });

  _exports.default = _default;
});