define("bo/components/table-default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mappedFields: Ember.computed.map('fieldsList', function (field, index) {
      return {
        width: this.widthAt(index),
        value: field
      };
    }),
    widthAt: function (index) {
      return this.get('fieldsWidth') && this.get('fieldsWidth').get(index) || "auto";
    },
    numberOfItems: Ember.computed('fieldsList', function () {
      return (this.get('fieldsList') || []).length;
    }),
    queryParams: Ember.computed('params', function () {
      return this.get('params') || {};
    }),
    // Whether or not to show the "previous" button
    colspan: Ember.computed('fieldsList', 'noAction', function () {
      return this.get('numberOfItems') + (this.get('noAction') ? 0 : 1);
    }),
    emptyLabel: "Aucun résultat"
  });

  _exports.default = _default;
});