define("bo/templates/bundles/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vO5Qi3e9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"card-box\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[0,\"Ajouter un nouveau bundle\"],[10],[0,\"\\n\\n  \"],[1,[27,\"bundles/edit-form\",null,[[\"model\"],[[23,[\"model\"]]]]],false],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\" Enregistrer \"],[3,\"action\",[[22,0,[]],\"save\"]],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bo/templates/bundles/new.hbs"
    }
  });

  _exports.default = _default;
});