define("bo/event_dispatcher", ["exports", "ember-gestures/event_dispatcher", "bo/config/environment"], function (_exports, _event_dispatcher, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const assign = Ember.assign || Ember.merge;
  let gestures = assign({}, {
    emberUseCapture: false,
    removeTracking: false,
    useFastPaths: false
  });
  gestures = assign(gestures, _environment.default.gestures);

  var _default = _event_dispatcher.default.extend({
    useCapture: gestures.emberUseCapture,
    removeTracking: gestures.removeTracking,
    useFastPaths: gestures.useFastPaths
  });

  _exports.default = _default;
});