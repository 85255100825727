define("bo/transforms/offer-gain-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize(serialized) {
      const label = {
        'eur': "Remboursement",
        'fids': "Fids",
        'code': "Code",
        'label': "Label",
        'creditable_point': "Créditables points"
      };
      return {
        raw: serialized,
        label: label[serialized]
      };
    },

    serialize(deserialized) {
      if (typeof deserialized === "string") {
        return deserialized;
      }

      return deserialized.raw;
    }

  });

  _exports.default = _default;
});