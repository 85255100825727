define("bo/components/forms/cells/offer-catalog-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      this.set("csvLinesCount", this.get('catalog.requirementCodes').length);
      this.set("catalog.entity", this.get('offer'));
    },

    csvLinesCount: 0,
    csvLines: null,
    codesList: Ember.computed("csvLines", "catalog.requirementCodes", function () {
      return (this.get("csvLines") || this.get('catalog.requirementCodes').map(code => code.code)).join("\n");
    }),
    actions: {
      saveCatalog(catalog) {
        catalog.save();
      },

      deleteCatalog(model, index, catalog) {
        if (catalog.isNew) {
          model.removeAt(index);
        } else {
          catalog.destroyRecord();
        }
      },

      uploadCSV(div, index, catalog) {
        const input = Ember.$(`.${div} input[type=file]`).get(index);

        if (input.files && input.files[0]) {
          const base64Reader = new FileReader();

          base64Reader.onload = e => {
            const fileToUpload = e.srcElement.result;
            catalog.set("requirementCodeFile", fileToUpload);
            catalog.save();
          };

          base64Reader.readAsDataURL(input.files[0]);
          const textReader = new FileReader();

          textReader.onload = e => {
            var csv = e.target.result;
            this.set('csvLines', csv.split('\n'));
            this.set('csvLinesCount', csv.split('\n').length);
          };

          textReader.readAsText(input.files[0]);
        }
      }

    }
  });

  _exports.default = _default;
});