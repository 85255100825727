define("bo/templates/components/side-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7WiqY8Xm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"left side-menu\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"sidebar-inner slimscrollleft\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"id\",\"sidebar-menu\"],[9],[0,\"\\n      \"],[7,\"ul\"],[9],[0,\"\\n        \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"bundles.index\"],[[\"class\"],[\"waves-effect\"]],{\"statements\":[[0,\"            \"],[7,\"i\"],[11,\"class\",\"mdi mdi-view-dashboard\"],[9],[10],[0,\"\\n            \"],[7,\"span\"],[9],[0,\"Bundles\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"clearfix\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"clearfix\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bo/templates/components/side-bar.hbs"
    }
  });

  _exports.default = _default;
});