define("bo/transforms/offer-actions-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "clip": "Clip",
    "scan_product": "Scan product",
    "scan_verification": "Scan verification",
    "save_ticket": "Save ticket",
    "save_photo": "Save photo",
    "link": "Link",
    "redirection": "Redirection",
    "check_billing_address": "Check billing address",
    "check_delivery_address": "Check delivery address",
    "check_bank_account": "Check bank account",
    "question": "Question",
    "answer": "Answer",
    "burn_fids": "Burn fids",
    "create_stripe_pay_in": "Create stripe pay in",
    "confirm_stripe_pay_in": "Confirm stripe pay in",
    "burn_creditable_points": "Burn creditable points",
    "pending": "Pending",
    "cancel": "Cancel"
  };
  _exports.default = _default;
});