define("bo/transforms/offer-action-type", ["exports", "ember-data", "bo/transforms/offer-actions-types"], function (_exports, _emberData, _offerActionsTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize(serialized) {
      const label = _offerActionsTypes.default;
      return {
        raw: serialized,
        label: label[serialized]
      };
    },

    serialize(deserialized) {
      if (typeof deserialized === "string") {
        return deserialized;
      } else if (deserialized) {
        return deserialized.raw;
      }

      return {};
    }

  });

  _exports.default = _default;
});