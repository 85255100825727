define("bo/templates/components/table-cells/notification-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2wak0ecF",
    "block": "{\"symbols\":[\"action\"],\"statements\":[[7,\"span\"],[11,\"class\",\"no-wrap\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"item\",\"actions\"]]],null,{\"statements\":[[0,\"    • \"],[1,[22,1,[\"name\"]],false],[0,\": \"],[1,[22,1,[\"url\"]],false],[7,\"br\"],[9],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bo/templates/components/table-cells/notification-actions.hbs"
    }
  });

  _exports.default = _default;
});