define("bo/templates/components/input-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+8gi6Tia",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"input-group\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"input-group-prepend\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn input-group-text waves-effect waves-dark\"],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"fas fa-times\"],[9],[10],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"search\",\"\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"class\",\"placeholder\",\"insert-newline\"],[\"text\",[23,[\"searchValue\"]],\"form-control\",[23,[\"placeholder\"]],[27,\"action\",[[22,0,[]],\"search\"],null]]]],false],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"input-group-append\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn btn-dark waves-effect waves-light\"],[9],[0,\"\\n        Rechercher\\n      \"],[3,\"action\",[[22,0,[]],\"search\",[23,[\"searchValue\"]]]],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bo/templates/components/input-search.hbs"
    }
  });

  _exports.default = _default;
});