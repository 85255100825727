define("bo/templates/components/table-cells/content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tkUoNh1Y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"codeParams\"]]],null,{\"statements\":[[0,\"  \"],[1,[23,[\"codeParams\",\"bcid\"]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"codeParams\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[7,\"br\"],[9],[10],[7,\"small\"],[9],[0,\"(Longueur: \"],[1,[23,[\"codeParams\",\"length\"]],false],[0,\")\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bo/templates/components/table-cells/content.hbs"
    }
  });

  _exports.default = _default;
});