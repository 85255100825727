define("bo/templates/components/table-cells/offercell-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7nyM0hCu",
    "block": "{\"symbols\":[\"cell\"],\"statements\":[[1,[23,[\"item\",\"title\"]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"small\"],[9],[0,\"\\n  \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"item\",\"role\",\"raw\"]],\"link\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[1,[23,[\"item\",\"urlScheme\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"item\",\"role\",\"raw\"]],\"list\"],null]],null,{\"statements\":[[4,\"each\",[[23,[\"item\",\"children\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[1,[22,1,[\"title\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bo/templates/components/table-cells/offercell-value.hbs"
    }
  });

  _exports.default = _default;
});