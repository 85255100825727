define("bo/serializers/application", ["exports", "active-model-adapter"], function (_exports, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    keyForAttribute(attr) {
      return attr;
    },

    keyForRelationship(attr, kind) {
      if (kind === "belongsTo") {
        return `${attr}Id`;
      }

      return attr;
    }

  });

  _exports.default = _default;
});