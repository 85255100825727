define("bo/controllers/bundles/new", ["exports", "bo/controllers/bases/form"], function (_exports, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _form.default.extend({});

  _exports.default = _default;
});