define("bo/models/bundle", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    js_version: _emberData.default.attr("number"),
    from: _emberData.default.attr("number"),
    to: _emberData.default.attr("number"),
    platform: _emberData.default.attr("string"),
    file_url: _emberData.default.attr("string"),
    release_note: _emberData.default.attr("string"),
    file: _emberData.default.attr("base64")
  });

  _exports.default = _default;
});