define("bo/components/forms/cells/illustration-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      this.set("illustration.pictureParams", {
        "url": this.get("illustration.pictureParams.url")
      });
    },

    actions: {
      didChange(illustration, event) {
        illustration.set("pictureParams.url", event.target.value);
        illustration.set("changed", true);
      }

    }
  });

  _exports.default = _default;
});