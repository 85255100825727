define("bo/templates/components/table-cells/fids", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zQYoE+C7",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"fids\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[12,\"href\",[28,[\"/fids/\",[27,\"get\",[[23,[\"item\"]],\"id\"],null]]]],[9],[1,[21,\"fids\"],false],[0,\" Fids\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "bo/templates/components/table-cells/fids.hbs"
    }
  });

  _exports.default = _default;
});