define("bo/components/table-cells/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    field: Ember.computed('item', function () {
      let field = this.get('type-field');

      if (field.indexOf(':') > -1) {
        field = field.split(':')[1];
      }

      return field;
    })
  });

  _exports.default = _default;
});