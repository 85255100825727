define("bo/routes/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async beforeModel() {
      if (await currentUser.isAuthenticated()) this.replaceWith("bundles");
    }

  });

  _exports.default = _default;
});