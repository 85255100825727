define("bo/controllers/bundles/index", ["exports", "bo/controllers/bases/index"], function (_exports, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _index.default.extend({
    queryParams: ["page", "perPage", "search", "version"],
    mappedVersions: function () {
      return ["7.0.0", "7.0.1"];
    },
    page: 1,
    perPage: 25,
    search: "",
    country: "FR",
    emptyLabel: Ember.computed("search", "country", function () {
      return (this.get("search") || "").length === 0 && (this.get("country") || "").length === 0 ? "Veuillez saisir les critères de recherche" : "Aucun résultat";
    }),
    actions: {
      filter(country) {
        this.set("country", country);
      }

    }
  });

  _exports.default = _default;
});