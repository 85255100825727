define("bo/components/table-cells/date", ["exports", "bo/components/table-cells/default", "moment"], function (_exports, _default2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _default2.default.extend({
    date: Ember.computed('item', function () {
      const field = this.get('field');
      const date = this.get(`item.${field}`);

      if (date == null) {
        return null;
      }

      return (0, _moment.default)(date).format("DD/MM/YYYY HH:mm:ss");
    })
  });

  _exports.default = _default;
});