define("bo/initializers/ember-cli-lightbox", ["exports", "bo/config/environment", "ember-cli-lightbox/initializers/ember-cli-lightbox"], function (_exports, _environment, _emberCliLightbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(...args) {
    return _emberCliLightbox.default.initialize.apply(null, [_environment.default['ember-cli-lightbox'], ...args]);
  }

  var _default = {
    name: _emberCliLightbox.default.name,
    initialize
  };
  _exports.default = _default;
});