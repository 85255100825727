define("bo/helpers/table-partial-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tablePartialCell = tablePartialCell;
  _exports.default = void 0;

  function tablePartialCell([field]) {
    let type = null;

    if (field.indexOf(':') > -1) {
      [type, field] = field.split(':');
    }

    return type || 'default';
  }

  var _default = Ember.Helper.helper(tablePartialCell);

  _exports.default = _default;
});