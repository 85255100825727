define("bo/routes/bundles/index", ["exports", "bo/routes/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    queryParams: {
      search: {
        refreshModel: true
      },
      version: {
        refreshModel: true
      }
    },

    model(params) {
      return Ember.RSVP.hash({
        fieldsList: ["platform", "from", "to", "js_version", "release_note", "file:file_url"],
        fieldsLabels: ["Platforme", "À partir de", "Jusqu'à", "Version JS", "Release Note", "Bundle"],
        fieldsWidth: ["100px", "100px", "100px", "100px"],
        bundles: this.store.query("bundle", params)
      });
    }

  });

  _exports.default = _default;
});